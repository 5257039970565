<template>
	<main
		class="container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="page-menu container-base">
			<div class="page-menu__grid">

				<div
					class="page-menu__left-column"
					ref="mainblock"
				>
					<RestaurantInfo
						class="page-menu__restaurant-info"
						@change="showRestaurantsForm"
						:name="curRest.name || ''"
						:ogrn="curRest.ogrn || ''"
						:inn="curRest.inn || ''"
					/>
					<h2 class="page-menu__title">Основное меню</h2>
					<div class="menu-page__swipe-wrapper">
						<v-touch
							ref="tapper"
							v-on:swipeup="swipeUp"
							v-on:swipedown="swipeDown"
						>
							<div
								class="menu-page__swipe-content"
								:class="{'menu-page__swipe-content--priority': showMobileRest,}"
							>
								<div
									class="menu-page__swipe-content-wr"
									:class="{ 'menu-page__swipe-content-wr--fixed': showMobileRest }"
									ref="swipeRest"
								>
									<div
										class="menu-page__swipe-top"
										@click="swipeDown"
									>
										<span class="menu-page__swipe-feature"></span>
									</div>
									<div>
										<Restaurants
											:restaurants="restaurantsList"
											@selectRestaurant="changeRest"
											:showMobileRest="showMobileRest"
										/>
									</div>
								</div>

							</div>
						</v-touch>
					</div>
					<div class="page-menu__date-and-time">
						<SwitchLine
							class="page-menu__date"
							:canSwitch="!currentBasket?.id"
							@itemChanged="switchDay"
							:selectedItem="currentDayStr"
							:items="[
										{name: 'Меню на сегодня', value: 'today'},
										{name: 'Меню на завтра', value: 'tomorrow'},
									]"
						/>
						<SwitchLine
							v-if="false"
							v-model="time"
							class="page-menu__time"
							:canSwitch="!currentBasket?.id"
							:items="[
										{name: 'Завтрак 8:30-10:30', value: 'breakfast', disabled: true},
										{name: 'Обед 11:30-16:30', value: 'lunch'},
										{name: 'Ужин 17:30-21:30', value: 'dinner'},
									]"
						/>
					</div>
					<BlockPreloader
						class="page-menu__preloader"
						v-if="menuLoading"
					/>
					<FoodTypeSelector
						v-if="!restClosed && !menuLoading"
						class="page-menu__food-type-selector hide-scroll"
						:constructorPage="constructorPage"
						:whithCount="true"
						:items="$store.state.mealByGroups"
						:sticky="foodTypeSelectorSticky"
						:style="{top: `${foodTypeSelectorShift}px`}"
						ref="foodtypeselector"
						@select="selectCategory"
					/>
					<div
						class="page-menu__filler"
						style="height: 134px"
						v-if="foodTypeSelectorSticky && !restClosed"
					/>
					<div
						class="page-menu__meal-times"
						v-if="!currentBasket?.id"
					>
						<div
							class="page-menu__meal-time"
							v-for="item in filteredMealTimesWithEmptyCheck"
							:key="item.value"
						>
							<AdditionalOffers
								:value="item.value"
								:name="item.name"
								:cover="item.cover"
								:background="item.background"
							/>
						</div>
					</div>
					<RestClosed
						class="page-menu__rest-closed"
						v-if="restClosed && !menuLoading"
						:tomorrow="$store.state.banner.showTomorrowOrderButton"
						:btnClick="switchDay"
					/>

					<SectionTitle
						v-if="!restClosed && !menuLoading && businessMeals.foodItems.length"
						class="page-menu__section-title"
						ref="lunches"
					>
						Бизнес-ланчи
						<span
							v-if="currentBasket?.collective"
							class="page-menu__collective-title"
						>
							<img src="@/assets/icons/collective.svg" alt="">
						</span>
					</SectionTitle>
					<BusinessMealList
						v-if="!restClosed && !menuLoading && businessMeals.foodItems.length"
						class="page-menu__business-lunch-list"
						:items="businessMeals.foodItems || []"
						:badge="'Собери свой ланч'"
						:description="'Добавляйте любые блюда в ваш конструктор ланча'"
						@setCurrentBusinessMealType="setCurrentBusinessMealType"
						:collective="currentBasket?.collective"
					/>
					<div v-show="!restClosed && !menuLoading">
						<div
							v-for="category in meals"
							:key="category.type"
						>
							<SectionTitle
								class="page-menu__section-title"
								:ref="category.type"
							>
								{{ category.title }}
								<span
									v-if="currentBasket?.collective"
									class="page-menu__collective-title"
								>
									<img src="@/assets/icons/collective.svg" alt="">
								</span>
							</SectionTitle>

							<MealList
								class="page-menu__meal-list-category"
								@selected="selectedItem = $event; selectedItemPopup = true;"
								:categoryBackground="getCategoryColor(category.type)"
								:items="category.foodItems || []"
								:collective="currentBasket?.collective"
							/>
						</div>
					</div>
				</div>
				<div
					class="page-menu__right-column"
					v-if="!menuLoading && !mobileCheck()"
					ref="minicart"
				>
					<MiniCart :style="{top: `${minicartShift}px`}" :collective="currentBasket?.collective" />
				</div>
			</div>
		</section>
		<BottomNavigation/>
		<Popup :visible.sync="showRestSelection">
			<Restaurants
				:restaurants="restaurantsList"
				@selectRestaurant="changeRest"
			/>
		</Popup>
		<Popup
			:visible.sync="selectedItemPopup"
			class="page-menu__meal-popup"
			:hideCloseBtn="mealPopupComponent != 'MealPopup'"
		>
			<Component
				:is="mealPopupComponent"
				v-if="selectedItem"
				@close="selectedItemPopup = false"
				:item="selectedItem"
				:id="parseInt(selectedItem.id)"
				:imgs="[selectedItem.preview_picture.thumb, selectedItem.detail_picture.thumb]"
				:type="selectedItem.type.name"
				:nutrition="{
					calories: selectedItem.kkal,
					proteins: selectedItem.belki,
					fats: selectedItem.jiri,
					carbs: selectedItem.uglevodi
				}"
				:rating="5"
				:name="selectedItem.name"
				:badges="[]"
				:composition="selectedItem.sostav"
				:discount="parseInt(selectedItem.discount)"
				:price="selectedItem.price"
				:inAppPrice="null"
				:weight="parseInt(selectedItem.weight)"
				:bonus="parseInt(selectedItem.balls)"
				:rest="curRest"
			/>
		</Popup>
		<Popup :visible.sync="isLunch">
			<PlaceOrder
				@closePopup="isLunch = false"
				:isMainPage="true"
				:isMealPage="$store.timeRange === 'breakfast' ? 'Завтраки' : 'Ужины'"
			/>
		</Popup>
		<!--				На первое время решили убрать это уведомление, в последующем будем показывать-->
		<!--		<CreateBasketInfo-->
		<!--			:class="['menu-page__basket-info', {'menu-page__basket-info&#45;&#45;show': createBasketInfoPopup}]"-->
		<!--			@closeBasketInfo="createBasketInfoPopup = false"-->
		<!--		/>-->
	</main>
</template>

<script>
import {useYandexMetrika} from "@/composables";
import RestaurantInfo from "../components/block/Menu/RestaurantInfo.vue";
import SwitchLine from "../components/ui/SwitchLine.vue";
import BlockPreloader from "../components/ui/BlockPreloader.vue";
import {mapActions, mapGetters} from "vuex";
import BottomNavigation from "@/components/nav/BottomNavigation";
import AdditionalOffers from "@/components/block/AdditionalOffers";
import PlaceOrder from "@/components/popup/PlaceOrder";
import {mobileCheck} from "@/helpers";
import jivoSite from "../mixins/jivoSite";
import search from '@/mixins/search.js';
import {loadYmap} from "vue-yandex-maps";

export default {
	name: "MenuPage",
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	components:
		{
			AdditionalOffers,
			RestaurantInfo,
			SwitchLine,
			BottomNavigation,
			BlockPreloader,
			PlaceOrder,
			FoodTypeSelector: () => import("../components/block/Menu/FoodTypeSelector.vue"),
			SectionTitle: () => import("../components/block/SectionTitle.vue"),
			BusinessMealList: () => import("../components/block/Menu/BusinessMealList.vue"),
			MiniCart: () => import("../components/cart/MiniCart.vue"),
			MealList: () => import("../components/block/Menu/MealList.vue"),
			RestClosed: () => import("../components/block/Menu/RestClosed.vue"),
			Restaurants: () => import("../components/popup/Restaurants.vue"),
			MealPopup: () => import("../components/block/Menu/MealPopup.vue"),
			MealMobilePopup: () => import("../components/block/Menu/MealMobilePopup.vue"),
			CreateBasketInfo: () => import("ui/CreateBasketInfo.vue"),
		},

	data: () => ({
		date: "today",
		time: "lunch",
		mealTimes: [
			{
				value: "breakfast",
				name: "Завтраки",
				cover: require("@/assets/img/breakfast.webp"),
				background: "linear-gradient(142.91deg, #E2FCFF 20.64%, #94ECFF 77.6%), #D9D9D9",
			},
			{
				value: "dinner",
				name: "Банкетное меню",
				cover: require("@/assets/img/banket.png"),
				background: "linear-gradient(142.91deg, #EFFFE2 20.64%, #94FFCB 77.6%), #D9D9D9",
			}
		],
		isScrolling: false,

		createBasketInfoPopup: false,

		breakfastEmpty: false,

		isLunch: false,

		constructorPage: false,

		minicartPos: 0,
		minicartShift: 0,

		foodTypeSelectorSticky: false,
		foodTypeSelectorShift: 0,

		showRestSelection: false,

		selectedItem: null,
		selectedItemPopup: false,

		showMobileRest: false,
		swipeRestHeight: 0,
	}),

	methods:
		{
			...mapActions({
				getRestDeliveryTime: "restaurant/getRestDeliveryTime",
				getBasketTypes: "getBasketTypes",
				getMenu: "getMenu",
			}),
			mobileCheck,
			// Записывает текущий тип конструктора
			setCurrentBusinessMealType()
			{
				this.$store.commit('choseBusinessMealType', "lunch")
			},
			// Проверяем есть ли в меню завтрак или ужин
			checkMealEmpty(meal)
			{
				return !!this.timeRangesWithMenu.includes(meal);
			},
			showRestaurantsForm(width)
			{
				if (width <= 767)
				{
					this.swipeUp()
				} else
				{
					this.showRestSelection = true
				}
			},
			swipeUp()
			{
				if (window.innerWidth <= 767)
				{
					this.showMobileRest = true;
					this.$refs.swipeRest.style.bottom = "0px";
					document.querySelector("body").classList.add("page-no-scroll");
				}
			},
			swipeDown()
			{
				if (window.innerWidth <= 767)
				{
					const swipeRest = this.$refs.swipeRest;

					if (!swipeRest) return;

					this.showMobileRest = false;
					swipeRest.style.bottom = "-" + this.swipeRestHeight + "px";
					document.querySelector("body").classList.remove("page-no-scroll");
				}
			},
			async switchDay(day)
			{
				await this.$store.dispatch("setDate", day);
			},

			// Обновление позиции корзины
			updateMinicartPos()
			{

				if (!this.$refs.mainblock || !this.$refs.minicart) return;

				// Если страница находится не в начале, смещение корзины вниз
				this.minicartShift = window.scrollY > 0 ? 20 : 0;

				// Если хэдер виден, смещение корзины под хэдер
				this.minicartShift += this.$store.state.ui.headerVisibility && window.scrollY > 0 ? 115 : 0;

				// Смещение по размеру списка категорий
				this.minicartShift += this.foodTypeSelectorSticky ? 72 : 0;
			},

			// Проверка видимости меню выбора категории
			updateFoodTypeSelectorVisibility()
			{
				const isHeaderVisible = this.$store.state.ui.headerVisibility;
				const isDesktop = window.innerWidth >= 990;
				const scrollY = window.scrollY;

				this.foodTypeSelectorShift = (isHeaderVisible && isDesktop) ? 120 : 0;

				const stickyThreshold = isDesktop ? 380 : 360;
				this.foodTypeSelectorSticky = scrollY >= stickyThreshold;
			},

			// Скролл до выбранной категории
			selectCategory(type)
			{
				const el = this.$refs[type].$el || this.$refs[type][0].$el;

				let top = el.getBoundingClientRect().top + window.pageYOffset - 100;

				if (window.scrollY > el.getBoundingClientRect().top + window.pageYOffset && window.innerWidth > 990)
				{
					top -= 120;
				}

				window.scrollTo({top, behavior: "smooth"});
			},

			// Изменение ресторана
			async changeRest(rest)
			{
				this.showRestSelection = false;

				this.$store.commit("setRestarauntId", rest.id);
				await this.getMenu();
				await this.$store.dispatch("getCart");
				this.swipeDown();
			},

			getCategoryColor(category)
			{
				let res = this.$store.state.menuCategories.find(c => c.type == category);

				if (res.color)
					return `#${res.color}`;
				else
					return "#fff";
			},
			// Загрузка текущего ресторана по id
			async loadRestaurantById(pathId)
			{
				const address = await this.$store.dispatch("fetchCurrentRestaurant", pathId);

				if (!address) return;

				this.$store.commit("map/setDeliveryAddress", address);

				await loadYmap({
					apiKey: process.env.VUE_APP_YA_API_KEY,
					suggestApiKey: process.env.VUE_APP_YA_SUGGEST_API_KEY,
					lang: 'ru_RU',
					coordorder: 'latlong',
					version: '2.1'
				});

				await this.setRestaurants({incommingRestaurants: this.restaurants});

				await this.getMenu('lunch');
			},
			openCreateBasketInfoPopup()
			{
				this.createBasketInfoPopup = true;
				sessionStorage.setItem('createBasketInfoPopup', 'opened');
			},
			addEventListeners()
			{
				window.addEventListener('scroll', this.updateFoodTypeSelectorVisibility);
				window.addEventListener('resize', this.updateFoodTypeSelectorVisibility);
				window.addEventListener("scroll", this.updateMinicartPos, {passive: true});
			}
		},
	computed:
		{
			...mapGetters({
				curRest: "order/currentRestaurant",
				currentDeliveryAddress: 'map/getDeliveryAddress',
				currentDayStr: 'currentDayStr',
				restaurantsList: 'getRestaurants',
				timeRangesWithMenu: "getTimeRangesWithMenu",
				currentRestarauntId: "getRestarauntId",
				currentBasket: "getCurrentBasket",
				menuProductsCount: "getMenuProductsCount",
				menuLoading: "getMenuLoading",
				timeRange: "getTimeRange"
			}),
			/** Фильтруем массив из завтрака и ужина, по критериям:
			 * 1. Если у этого ресторана есть завтраки или ужины - item.isEmpty
			 * 2. Если меню еще загружается - this.menuLoading
			 */
			filteredMealTimesWithEmptyCheck()
			{
				return this.mealTimesWithEmptyCheck.filter(item => item.isEmpty && !this.menuLoading);
			},
			// Формируем массив из завтрака и ужина
			mealTimesWithEmptyCheck()
			{
				return this.mealTimes.map(meal => ({
					...meal,
					isEmpty: this.checkMealEmpty(meal.value),
				}));
			},
			restClosed()
			{
				return !this.menuProductsCount;
			},
			meals()
			{
				return this.$store.state.mealByGroups.filter(meal => meal.type !== "lunches") || {foodItems: []};
			},
			businessMeals()
			{
				return this.$store.state.mealByGroups.filter(meal => meal.type === "lunches")[0] || {foodItems: []};
			},
			mealPopupComponent()
			{
				return window.innerWidth < 991 ? "MealMobilePopup" : "MealPopup";
			},
		},
	watch:
		{
			async currentDayStr()
			{
				await this.$store.dispatch("getBannerData");
			},
			async restaurantsList()
			{
				if (this.restaurantsList.length === 0)
					this.$router.push("/");
				else if (this.restaurantsList.length === 1)
				{
					await this.$store.commit("setRestarauntId", this.restaurantsList[0].id);
					await this.$store.dispatch("setDate", this.currentDayStr);
				} else if (!!this.currentRestarauntId)
				{
					this.$store.commit("setRestarauntId", this.currentRestarauntId);
					await this.$store.dispatch("setDate", this.currentDayStr);
				} else
				{
					this.$store.commit("setRestarauntId", false);
					this.showRestSelection = true;
				}
			},
			async currentRestarauntId()
			{
				this.showJivo(this.currentRestarauntId);

				await this.getRestDeliveryTime();
			},
			async timeRange()
			{
				await this.getMenu('lunch');
			}
		},
	mixins: [jivoSite, search],
	async mounted()
	{
		await this.getRestDeliveryTime();

		this.showJivo(this.currentRestarauntId);

		this.addEventListeners();

		const currentPathId = this.$route.params.id;
		if (currentPathId) await this.loadRestaurantById(currentPathId)

		if (!this.currentDeliveryAddress)
			await this.$store.dispatch('checkAddressFromCookie', {withStoreAddress: true});

		this.$store.commit('setTimeRange', 'lunch');

		this.yandexMetrika();

		this.swipeRestHeight = this.$refs.swipeRest.offsetHeight;
		this.$refs.swipeRest.style.bottom = "-" + this.$refs.swipeRest.offsetHeight + "px";

		if (mobileCheck())
		{
			await this.getBasketTypes();

			if (!sessionStorage.getItem('createBasketInfoPopup') && !this.currentBasket?.collective) this.openCreateBasketInfoPopup();
		}
	},
	// Оставили этот код в случае если решим вернуть функционал
	// updated()
	// {
	// 	// Если время обедов закончилось, то перенаправляем пользователя на страницу с ужинами, если они есть
	// 	if (this.restClosed && this.filteredMealTimesWithEmptyCheck)
	// 	{
	// 		const dinnerItem = this.filteredMealTimesWithEmptyCheck.find(item => item.name === "Ужины");
	// 		if (dinnerItem)
	// 		{
	// 			window.location.href = '/application/dinner';
	// 		}
	// 	}
	// },
	beforeDestroy()
	{
		this.hideJivo()

		window.removeEventListener('scroll', this.updateFoodTypeSelectorVisibility);
		window.removeEventListener("scroll", this.updateMinicartPos);
		window.removeEventListener('resize', this.updateFoodTypeSelectorVisibility);
	},
};
</script>

<style lang="scss">
.menu-page__swipe-wrapper
{display: none;}

.menu-page__swipe-top
{
	position: absolute;
	height: 28px;
	width: calc(100% - 40px);
	margin: 0 20px 0 20px;
	background: #FFFFFF;
	text-align: center;
}

.menu-page__basket-info
{
	position: fixed;
	z-index: 2;
	bottom: 96px;
	left: 50%;
	transform: translateX(-50%);

	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.menu-page__basket-info--show
{
	visibility: visible;
	opacity: 1;
}

.menu-page__swipe-feature
{
	display: inline-block;
	width: 44px;
	height: 4px;
	background: #E3E3E3;
	border-radius: 16px;
}

.menu-page__swipe-content
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 5;

	&--priority
	{z-index: 12;}
}

.menu-page__swipe-content-wr
{
	max-width: 354px;
	margin-left: 10px;
	background: #FFFFFF;
}

.page-menu__title
{
	margin-bottom: 24px;
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	letter-spacing: -0.5px;
	color: #3D4248;
}

.page-menu__collective-title
{
	display: flex;
	align-items: center;
	border-left: 1px solid $greyDelimiter;
	height: 24px;
	padding-left: 16px;

	@media (max-width: 999px)
	{
		display: block;
	}
}

.page-menu__meal-times
{
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
	margin-bottom: 32px;
}

.page-menu__meal-time
{
	max-width: 447px;
	min-width: 310px;
	display: flex;
	flex-grow: 1;

	ul
	{margin: 0;}
}

.page-menu .page-menu__preloader
{
	height: 440px;
	border-radius: 24px;
}

.page-menu
{
	overflow: visible;
	min-height: 88vh;

	&__grid
	{
		display: grid;
		grid-template-columns: minmax(400px, 1fr) 354px;
		gap: 0px 56px;
		width: 100%;
		position: relative;
	}

	&__rest-closed
	{margin-bottom: 60px;}

	&__restaurant-info
	{margin-bottom: 24px;}

	&__date-and-time
	{
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
	}

	&__date
	{width: 311px;}

	&__time
	{width: 453px;}

	&__food-type-selector
	{
		margin-bottom: 23px;
		padding-top: 12px;
		padding-bottom: 23px;

		margin-left: -44px;
		margin-right: -44px;
		padding-left: 44px;
		padding-right: 44px;
		max-width: 954px;
		transition: top 0.2s ease-in-out;
	}

	&__food-type-selector.sticky
	{
		padding: 0;
		margin: 0;
		max-width: unset;
	}

	&__section-title
	{
		gap: 14px;
		margin-bottom: 32px;
		align-items: center;
	}

	&__business-lunch-list
	{margin-bottom: 60px;}

	&__meal-list-category
	{margin-bottom: 24px;}

	&__meal-popup
	{

		.popup-block
		{
			width: 960px;
			border-radius: 24px;
			padding: 40px;
		}
	}

	@media (max-width: 990px)
	{
		&__meal-popup
		{

			.popup-block
			{
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				max-width: unset;
				max-height: unset;
				height: 100%;
				border-radius: 0;
				padding: 0;
			}

		}
	}

	@media (max-width: 1439px)
	{

		&__grid
		{gap: 0 48px;}


		&__time
		{display: none;}

	}
	@media (max-width: 1024px)
	{
		&__grid
		{
			grid-template-columns: minmax(400px, 1fr) 340px;
			gap: 0 32px;
		}
	}
	@media (max-width: 1000px)
	{
		&__grid
		{display: block;}
		&__right-column
		{display: none;}
	}
	@media (max-width: 767px)
	{
		padding-bottom: 64px;

		.menu-page__swipe-wrapper
		{display: flex;}
		.menu-page__swipe-content-wr
		{
			margin-left: 0;
			position: fixed;
			z-index: 5;
			height: 0;
			width: 100%;
			max-width: unset;
			transition: .2s ease-in-out;

			&--fixed
			{
				height: 100%;
				padding-right: 0;
				padding-left: 0;
				padding-top: 0;
			}
		}
	}
	@media (max-width: 567px)
	{
		.page-menu__grid
		{padding-bottom: 25px;}
		&__food-type-selector
		{

			margin-left: -32px;
			margin-right: -32px;
			padding-left: 32px;
			padding-right: 32px;
		}

		.page-menu__title
		{
			font-size: 32px;
			line-height: 40px;
		}

		&__date
		{width: 100%;}

		&__section-title
		{margin-bottom: 24px;}
	}

}

</style>
